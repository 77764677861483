.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox__giChd {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.freeBox__cjcP {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 65px;
  align-self: flex-start;
  margin-bottom: 28px;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.text__sxuxn {
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h1 {
  font-size: 72px;
  font-weight: 900;
}
@media (min-width: 0px) and (max-width: 800px) {
  .h1 {
    font-size: 60px;
  }
}
.span {
  color: #ffffff00;
  background: linear-gradient(0deg, #ff7b00 0%, #f2d599 100%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  background-clip: text;
  -webkit-background-clip: text;
}
.text__xZngr {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h4 {
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
}
