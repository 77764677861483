.root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  flex-wrap: wrap;
  max-width: 100%;
  position: relative;
  background: #000000;
  min-width: 0;
  padding: 0px 64px 64px;
}
@media (min-width: 0px) and (max-width: 800px) {
  .root {
    padding: 40px;
  }
}
.freeBox__mKdH {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__mFoN5 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-width: 1380px;
  flex-wrap: nowrap;
  align-content: stretch;
  min-width: 0;
  padding: 8px;
}
.rowWrapper {
  flex-direction: row;
  display: flex;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  max-width: 1380px;
  flex-grow: 0;
  min-width: 0;
  padding: 8px;
}
.rowWrapper > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.rowWrapper > :global(.__wab_flex-container) > :nth-child(2n + 1) {
  width: calc((100% - 2 * 20px) * 8 / 12);
}
.rowWrapper > :global(.__wab_flex-container) > :nth-child(2n + 2) {
  width: calc((100% - 2 * 20px) * 4 / 12);
}
.rowWrapper > :global(.__wab_flex-container) > *,
.rowWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.rowWrapper > :global(.__wab_flex-container) > picture > img,
.rowWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 20px;
}
@media (min-width: 0px) and (max-width: 800px) {
  .rowWrapper {
    flex-direction: row;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .rowWrapper > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .rowWrapper > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * 20px) * 12 / 12);
  }
}
.colmn1 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.h3__taePa {
  position: relative;
  left: auto;
  top: auto;
  align-self: flex-start;
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  color: #0ea7de00;
  text-align: left;
  text-transform: none;
  line-height: 1.2;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  background: linear-gradient(
      34deg,
      var(--token-i0T7JCCrR) 0%,
      var(--token-jOxntaLiP) 41%,
      var(--token-HUcV72u5sT2) 71%,
      #00f6ff 100%
    )
    0% 0% / 100% 100% repeat padding-box /* clip: text **/;
  background-clip: text;
  -webkit-background-clip: text;
}
.text__dri2T {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  left: auto;
  top: auto;
  min-width: 0;
}
.link___5XUwf {
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  text-decoration-line: underline;
  min-width: 0;
}
.colmn2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.h3__s2M8G {
  position: relative;
  left: auto;
  top: auto;
  align-self: flex-start;
  margin-bottom: 12px;
  font-family: "Montserrat", sans-serif;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  color: transparent;
  text-align: left;
  text-transform: none;
  line-height: 1.2;
  letter-spacing: 0px;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  background: linear-gradient(55deg, #c600e7 0%, #5d2be4 42%, #0069ff 86%) 0% 0% /
    100% 100% repeat padding-box /* clip: text **/;
  background-clip: text;
  -webkit-background-clip: text;
}
.link__awByn {
  position: relative;
  width: auto;
  height: auto;
  max-width: 556px;
  left: auto;
  top: auto;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #535353;
  line-height: 1.6;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding: 0px;
  margin: 0px 0px 7px;
}
.link__hMjb {
  width: auto;
  height: auto;
  max-width: 800px;
  align-self: flex-start;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #535353;
  line-height: 1.6;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding: 0px;
  margin: 0px 0px 7px;
}
.link__kb8Yy {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.6;
  font-family: "Inter", sans-serif;
  color: #535353;
  width: auto;
  height: auto;
  max-width: 800px;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding: 0px;
  margin: 0px 0px 7px;
}
.link__sZqqh {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 300;
  color: #535353;
  line-height: 1.6;
  font-style: normal;
  text-align: left;
  text-transform: none;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  padding: 0px;
  margin: 0px 0px 7px;
}
.freeBox___6Pega {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  align-self: flex-start;
  padding: 0px;
}
.text___5TZ0N {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  padding-left: 24px;
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
}
.nowPlayingWrapper {
  display: flex;
  width: auto;
}
.freeBox___00SHs {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px 24px;
}
.img__rGvKk {
  position: relative;
  object-fit: cover;
  max-width: 50%;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.img__rGvKk > picture > img {
  object-fit: cover;
}
.img__rGvKk > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox__yrWk1 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px 8px 8px 11px;
  margin: 0px;
}
.img {
  object-fit: cover;
  max-width: 100%;
  width: 21px;
  height: 21px;
  flex-shrink: 0;
  margin: 0px 0px 11px;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.slotTargetSpotifySongName {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
}
.slotTargetSpotifySongArtist {
  font-family: "Montserrat", sans-serif;
  font-size: 8px;
}
.text__hO1Qh {
  position: relative;
  width: 100%;
  height: auto;
  max-width: none;
  left: auto;
  top: auto;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #535353;
  text-align: left;
  text-transform: none;
  line-height: 1.7;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
  min-width: 0;
  padding: 8px 0px 0px 24px;
}
.link__ln6D4 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #3291ff;
  text-align: left;
  text-transform: none;
  line-height: 1.7;
  letter-spacing: normal;
  white-space: pre-wrap;
  user-select: text;
  text-decoration-line: none;
  text-overflow: clip;
}
