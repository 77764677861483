.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.columns {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 0px;
}
.columns > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 5 / 12);
}
.columns > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 7 / 12);
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__bUinO {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--token-i0T7JCCrR);
  min-width: 0;
  padding: 50px 29px 20px 80px;
}
.freeBox__ntdMb {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  padding: 8px;
}
.text__zs5Gw {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: var(--token-HUcV72u5sT2);
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 42px;
  text-align: left;
  align-self: auto;
  min-width: 0;
}
.svg {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: flex-start;
  width: 138px;
  height: 1em;
  transform: scaleX(1) scaleY(0.15) scaleZ(1);
  flex-shrink: 0;
}
.text__tg6Vp {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 300;
  min-width: 0;
}
.column__tsGh0 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
.freeBox__ppaKm {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  left: auto;
  top: auto;
  margin-bottom: 40px;
  min-width: 0;
  padding: 0px;
}
.img {
  object-fit: cover;
  max-width: 100%;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.text__faNcx {
  position: absolute;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Montserrat", sans-serif;
  color: var(--token-i0T7JCCrR);
  left: auto;
  top: auto;
  z-index: 1;
  font-size: 10px;
  background: #ffffff;
  bottom: 0;
  right: 0;
  padding: 4px;
}
