.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
  background: none;
  overflow-y: visible;
  min-width: 0;
  min-height: 0;
}
.freeBox__cE69 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 691px;
  max-width: 100%;
  background: url("./images/heroBgpng4.png") top 50% left 50% / cover repeat,
    url("./images/heroBgpng3.png") top 50% left 50% / cover repeat;
  min-width: 0;
  flex-shrink: 0;
  padding: 8px;
}
.columns__lijuP {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-self: center;
  min-width: 0;
  padding: 8px;
}
.columns__lijuP > :nth-child(2n + 1) {
  width: calc((100% - 2 * 0px) * 3 / 12);
}
.columns__lijuP > :nth-child(2n + 2) {
  width: calc((100% - 2 * 0px) * 9 / 12);
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__lijuP {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__lijuP > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__u98BU {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.column__ryxCs {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  padding: 8px;
}
.tilt:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.img__mowIa {
  object-fit: cover;
  max-width: 150%;
  position: absolute;
  left: 25%;
  top: -30%;
  transform: translateX(0px) translateY(0px) translateZ(-80px);
  width: 130%;
  filter: contrast(65%);
  transform-style: preserve-3d;
}
.img__mowIa > picture > img {
  object-fit: cover;
}
.img__mowIa > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.img__mowIa * {
  transform-style: preserve-3d;
}
@media (min-width: 0px) and (max-width: 800px) {
  .img__mowIa {
    top: 9%;
    left: 17%;
    width: 150%;
    transform-style: preserve-3d;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .img__mowIa * {
    transform-style: preserve-3d;
  }
}
.freeBox__fgbEu {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.heroHeading:global(.__wab_instance) {
  max-width: 100%;
}
.contactLink {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-jOxntaLiP);
  cursor: pointer;
  align-self: flex-start;
  padding: 10px 15px;
  margin: 8px;
}
.text__isPx {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: 300;
  color: #ffffff;
}
.svg__dW1O {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 34px;
  height: 6px;
  display: block;
  align-self: center;
  margin-left: 5px;
  flex-shrink: 0;
}
.columns__x5Xa {
  flex-direction: row;
  position: relative;
  width: auto;
  height: auto;
  flex-shrink: 1;
  display: none;
  padding: 8px;
}
.columns__x5Xa > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  margin-left: calc(0px - 50px);
  width: calc(100% + 50px);
}
.columns__x5Xa > :global(.__wab_flex-container) > :nth-child(4n + 1) {
  width: calc((100% - 4 * 50px) * 3 / 12);
}
.columns__x5Xa > :global(.__wab_flex-container) > :nth-child(4n + 2) {
  width: calc((100% - 4 * 50px) * 3 / 12);
}
.columns__x5Xa > :global(.__wab_flex-container) > :nth-child(4n + 3) {
  width: calc((100% - 4 * 50px) * 3 / 12);
}
.columns__x5Xa > :global(.__wab_flex-container) > :nth-child(4n + 4) {
  width: calc((100% - 4 * 50px) * 3 / 12);
}
.columns__x5Xa > :global(.__wab_flex-container) > *,
.columns__x5Xa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__x5Xa > :global(.__wab_flex-container) > picture > img,
.columns__x5Xa
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 50px;
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__x5Xa {
    width: auto;
    height: auto;
    flex-direction: row;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__x5Xa > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: calc(0px - 10px);
    height: calc(100% + 10px);
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__x5Xa > :global(.__wab_flex-container) > :nth-child(2n + 1) {
    width: calc((100% - 2 * 50px) * 6 / 12);
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__x5Xa > :global(.__wab_flex-container) > :nth-child(2n + 2) {
    width: calc((100% - 2 * 50px) * 6 / 12);
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__x5Xa > :global(.__wab_flex-container) > *,
  .columns__x5Xa > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .columns__x5Xa > :global(.__wab_flex-container) > picture > img,
  .columns__x5Xa
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 10px;
  }
}
.column__jvJaC {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
.freeBox__whmJ9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      35deg,
      var(--token-HUcV72u5sT2) 0%,
      #01d55b 40%,
      #009631 81%
    )
    0% 0% / 100% 100% repeat;
  border-radius: 4px;
  padding: 2px;
}
.link__eQwG9 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(
        40deg,
        var(--token-HUcV72u5sT2) 0%,
        #01d55b 57%,
        #009631 100%
      )
      0% 0% / cover repeat padding-box /* clip: text **/,
    var(--token-UfZZzyooH);
  color: #ffffff00;
  align-self: center;
  width: 150px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
  border-radius: 4px;
  padding: 15px;
}
.column__iHov2 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
.freeBox__d6U9P {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      20deg,
      var(--token-HUcV72u5sT2) 0%,
      var(--token-jOxntaLiP) 38%,
      var(--token-i0T7JCCrR) 100%
    )
    0% 0% / 100% 100% repeat;
  border-radius: 4px;
  padding: 2px;
}
.link__vFsw6 {
  position: relative;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(
        40deg,
        var(--token-HUcV72u5sT2) 0%,
        var(--token-jOxntaLiP) 38%,
        var(--token-i0T7JCCrR) 97%
      )
      0% 0% / cover repeat padding-box /* clip: text **/,
    var(--token-UfZZzyooH);
  color: #ffffff00;
  align-self: center;
  width: 150px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
  border-radius: 4px;
  padding: 15px;
}
.column__gpubN {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
.freeBox__m71L {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
      47deg,
      var(--token-HUcV72u5sT2) 0%,
      #700ede 33%,
      #d802d8 100%
    )
    top 50% left 50% / cover repeat;
  border-radius: 4px;
  padding: 2px;
}
.link__rtfK3 {
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(
        40deg,
        var(--token-HUcV72u5sT2) 0%,
        #700ede 38%,
        #d802d8 97%
      )
      0% 0% / cover repeat padding-box /* clip: text **/,
    var(--token-UfZZzyooH);
  color: #ffffff00;
  align-self: center;
  width: 150px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  position: relative;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
  border-radius: 4px;
  padding: 15px;
}
.column__qJzX7 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  background: none;
  min-width: 0;
  border-radius: 4px;
}
.freeBox__lftHx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  background: linear-gradient(44deg, #ffc812 0%, #ff9117 52%, #ff0000 100%) 0%
    0% / 100% 100% repeat;
  border-radius: 4px;
  padding: 2px;
}
.link__mYiZ {
  position: relative;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(40deg, #ffc812 0%, #ff9117 38%, #ff0000 97%) 0% 0% /
      cover repeat padding-box /* clip: text **/,
    var(--token-UfZZzyooH);
  color: #ffffff00;
  align-self: center;
  width: 150px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  background-clip: text, border-box;
  -webkit-background-clip: text, border-box;
  border-radius: 4px;
  padding: 15px;
}
.link__rv4Ul {
  position: fixed;
  top: 0;
  left: auto;
  right: 0;
  z-index: 1;
  display: flex;
}
.freeBox__oKpBn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: var(--token-HUcV72u5sT2);
  padding: 35px;
}
.svg__atbLq {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
}
.freeBox__ae5G {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: linear-gradient(
        59deg,
        #0b2f33 0%,
        var(--token-i0T7JCCrR) 42%,
        #000e21 100%
      )
      0% 0% / 100% 100% repeat,
    var(--token-UfZZzyooH);
  min-width: 0;
  padding: 8px;
}
.text___9IJo {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  padding-right: 21px;
  padding-left: 21px;
  font-size: 35px;
  min-width: 0;
}
.columns__rvwcw {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  flex-shrink: 1;
  align-self: center;
  min-width: 0;
  padding: 20px;
}
.columns__rvwcw > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - 40px);
  width: calc(100% + 40px);
}
.columns__rvwcw > :global(.__wab_flex-container) > :nth-child(3n + 1) {
  width: calc((100% - 3 * 40px) * 4 / 12);
}
.columns__rvwcw > :global(.__wab_flex-container) > :nth-child(3n + 2) {
  width: calc((100% - 3 * 40px) * 4 / 12);
}
.columns__rvwcw > :global(.__wab_flex-container) > :nth-child(3n + 3) {
  width: calc((100% - 3 * 40px) * 4 / 12);
}
.columns__rvwcw > :global(.__wab_flex-container) > *,
.columns__rvwcw > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.columns__rvwcw > :global(.__wab_flex-container) > picture > img,
.columns__rvwcw
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 40px;
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__rvwcw {
    width: 100%;
    height: auto;
    flex-direction: row;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__rvwcw > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__rvwcw > :global(.__wab_flex-container) > :nth-child(1n + 1) {
    width: calc((100% - 1 * 40px) * 12 / 12);
  }
}
.column__ctvhu {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__urMwf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #fafafa;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
  padding: 16px;
}
.img__vaFq6 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 77px;
  flex-shrink: 0;
}
.img__vaFq6 > picture > img {
  object-fit: cover;
}
.img__vaFq6 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.h3__lkBcq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  min-width: 0;
}
.h3__jt1V {
  color: var(--token-i0T7JCCrR);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.text__fjqr5 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: var(--token-i0T7JCCrR);
  min-width: 0;
}
.column__k7Hi {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__tBgT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #fafafa;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
  padding: 16px;
}
.img__fwf90 {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 77px;
  flex-shrink: 0;
}
.img__fwf90 > picture > img {
  object-fit: cover;
}
.img__fwf90 > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.h3__yhxmm {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  min-width: 0;
}
.h3__u8414 {
  color: var(--token-i0T7JCCrR);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.text__hu3Rf {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: var(--token-i0T7JCCrR);
  min-width: 0;
}
.column__x4Qwh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__ar79Q {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #fafafa;
  min-width: 0;
  min-height: 0;
  border-radius: 5px;
  padding: 16px;
}
.img__zHfqh {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: 77px;
  flex-shrink: 0;
}
.img__zHfqh > picture > img {
  object-fit: cover;
}
.img__zHfqh > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.h3__rkuhP {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  min-width: 0;
}
.h3___5ARWw {
  color: var(--token-i0T7JCCrR);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 32px;
}
.text__p8GGj {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: var(--token-i0T7JCCrR);
  min-width: 0;
}
.freeBox__cSlR5 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 35px 70px;
}
@media (min-width: 0px) and (max-width: 800px) {
  .freeBox__cSlR5 {
    padding-left: 29px;
    padding-right: 29px;
  }
}
.svg__tMqGx {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 82px;
  color: var(--token-UfZZzyooH);
  margin-bottom: 20px;
  height: 1em;
  flex-shrink: 0;
}
.text__sFrIs {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h2 {
  font-family: "Montserrat", sans-serif;
  color: #0f2441;
  font-size: 60px;
  font-weight: 700;
}
.span {
  background: linear-gradient(
      49deg,
      #ae609e 22%,
      var(--token-jOxntaLiP) 53%,
      var(--token-HUcV72u5sT2) 74%,
      var(--token-HUcV72u5sT2) 93%
    )
    0% 0% / 100% 100% repeat padding-box /* clip: text **/;
  color: #ffffff00;
  background-clip: text;
  -webkit-background-clip: text;
}
.svg__siZQr {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-i0T7JCCrR);
  width: 23px;
  height: 2px;
  align-self: flex-start;
  margin-top: 16px;
  flex-shrink: 0;
}
.columns__omamc {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  align-items: stretch;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  min-width: 0;
  padding: 8px;
}
.columns__omamc > :nth-child(4n + 1) {
  width: calc((100% - 4 * 0px) * 3 / 12);
}
.columns__omamc > :nth-child(4n + 2) {
  width: calc((100% - 4 * 0px) * 3 / 12);
}
.columns__omamc > :nth-child(4n + 3) {
  width: calc((100% - 4 * 0px) * 3 / 12);
}
.columns__omamc > :nth-child(4n + 4) {
  width: calc((100% - 4 * 0px) * 3 / 12);
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__omamc {
    width: 100%;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
    min-width: 0;
  }
}
@media (min-width: 0px) and (max-width: 800px) {
  .columns__omamc > :nth-child(1n + 1) {
    width: calc((100% - 1 * 0px) * 12 / 12);
  }
}
.column__bvTPh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__yvrwa {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__q9Jz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h3__qfAj {
  color: #0ea7de00;
  background: linear-gradient(42deg, var(--token-jOxntaLiP) 0%, #f2d599 23%) 0%
    0% / 100% 100% repeat padding-box /* clip: text **/;
  font-family: "Montserrat", sans-serif;
  font-size: 38px;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
}
.svg__bVNa {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-i0T7JCCrR);
  align-self: flex-start;
  width: 62px;
  height: 1em;
  flex-shrink: 0;
}
.text__mbebG {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h5___9Aot5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.column__cFcRh {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__wEgpt {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__any1H {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h3___6KGZp {
  color: transparent;
  background: linear-gradient(311deg, #d043b3 61%, #f2d599 100%) 0% 0% / 100%
    100% repeat padding-box /* clip: text **/;
  font-family: "Montserrat", sans-serif;
  font-size: 38px;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
}
.svg___0GwsK {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-i0T7JCCrR);
  align-self: flex-start;
  width: 62px;
  height: 1em;
  flex-shrink: 0;
}
.text__kiOek {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h5__grxG {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.column__jx9Kp {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox___9C2Jw {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__bMkj4 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h3__wxLq9 {
  color: transparent;
  background: linear-gradient(0deg, #12cf55 0%, #7bf1ec 80%) 0% 0% / 100% 100%
    repeat padding-box /* clip: text **/;
  font-family: "Montserrat", sans-serif;
  font-size: 38px;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
}
.svg__stpsJ {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-i0T7JCCrR);
  align-self: flex-start;
  width: 62px;
  height: 1em;
  flex-shrink: 0;
}
.text__lYmCz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h5__pbt5B {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.column__cDnf5 {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 8px;
}
.freeBox__cI8GR {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.text__eiubq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h3__dGMi {
  font-size: 38px;
  font-family: "Inter", sans-serif;
  color: transparent;
  background: linear-gradient(48deg, var(--token-HUcV72u5sT2) 0%, #c999f2 15%)
    0% 0% / 100% 100% repeat padding-box /* clip: text **/;
  font-weight: 600;
  background-clip: text;
  -webkit-background-clip: text;
}
.svg__utLuo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-i0T7JCCrR);
  align-self: flex-start;
  width: 62px;
  height: 1em;
  flex-shrink: 0;
}
.text__qrEq {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
}
.h5__egOrq {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 20px;
}
