.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  left: auto;
  top: auto;
  min-width: 0;
  padding: 8px;
}
.text__n0Krz {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  align-self: flex-start;
  color: #ffffff;
  min-width: 0;
}
.text___8WvJb {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 600;
  color: #0ea7de00;
  background: linear-gradient(
      35deg,
      var(--token-i0T7JCCrR) 0%,
      var(--token-jOxntaLiP) 33%,
      var(--token-HUcV72u5sT2) 61%,
      #00f6ff 82%,
      #00ffbd 100%
    )
    padding-box /* clip: text **/;
  background-clip: text;
  -webkit-background-clip: text;
  min-width: 0;
}
.text__rsstl {
  position: relative;
  width: auto;
  height: auto;
  max-width: 800px;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 12px;
  background: #717a7b80;
  text-align: left;
  align-self: flex-start;
  letter-spacing: 2px;
  line-height: 2;
  padding: 5px 10px;
  margin: 15px 0px 0px;
}
