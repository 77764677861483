@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700&family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Montserrat%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C800%3B0%2C900&family=Lato%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C700%3B0%2C900&display=swap");

.plasmic_tokens {
  --token-HUcV72u5sT2: #06bcc1;
  --plasmic-token-brand-accent: var(--token-HUcV72u5sT2);
  --token-UfZZzyooH: #262626;
  --plasmic-token-brand-dark: var(--token-UfZZzyooH);
  --token-jOxntaLiP: #0ea7de;
  --plasmic-token-brand-main: var(--token-jOxntaLiP);
  --token-i0T7JCCrR: #0f2441;
  --plasmic-token-brand-dark-accent: var(--token-i0T7JCCrR);
}

.plasmic_default_styles {
  --mixin-RZ510ztpWy8_font-family: "IBM Plex Sans", sans-serif;
  --mixin-RZ510ztpWy8_font-size: 14px;
  --mixin-RZ510ztpWy8_font-weight: normal;
  --mixin-RZ510ztpWy8_font-style: normal;
  --mixin-RZ510ztpWy8_color: #ffffff;
  --mixin-RZ510ztpWy8_text-align: left;
  --mixin-RZ510ztpWy8_text-transform: none;
  --mixin-RZ510ztpWy8_line-height: 1.5;
  --mixin-RZ510ztpWy8_letter-spacing: normal;
  --mixin-RZ510ztpWy8_white-space: pre-wrap;
  --mixin-RZ510ztpWy8_user-select: text;
  --mixin-F7hHOkU-fOV_font-family: "Inter", sans-serif;
  --mixin-F7hHOkU-fOV_color: #000000;
  --mixin-F7hHOkU-fOV_font-size: 72px;
  --mixin-F7hHOkU-fOV_font-weight: 900;
  --mixin-F7hHOkU-fOV_letter-spacing: -4px;
  --mixin-F7hHOkU-fOV_line-height: 1;
  --mixin-F7hHOkU-fOV_white-space: pre-wrap;
  --mixin-gvKKhtLvBWe_font-family: "Inter", sans-serif;
  --mixin-gvKKhtLvBWe_color: #000000;
  --mixin-gvKKhtLvBWe_font-size: 48px;
  --mixin-gvKKhtLvBWe_font-weight: 700;
  --mixin-gvKKhtLvBWe_letter-spacing: -1px;
  --mixin-gvKKhtLvBWe_line-height: 1.1;
  --mixin-gvKKhtLvBWe_white-space: pre-wrap;
  --mixin-m2K2I4sQk2J_font-family: "Inter", sans-serif;
  --mixin-m2K2I4sQk2J_color: #000000;
  --mixin-m2K2I4sQk2J_font-size: 32px;
  --mixin-m2K2I4sQk2J_font-weight: 600;
  --mixin-m2K2I4sQk2J_letter-spacing: -0.8px;
  --mixin-m2K2I4sQk2J_line-height: 1.2;
  --mixin-m2K2I4sQk2J_white-space: pre-wrap;
  --mixin-KLxRtf9Nwl7_font-family: "Inter", sans-serif;
  --mixin-KLxRtf9Nwl7_color: #000000;
  --mixin-KLxRtf9Nwl7_font-size: 24px;
  --mixin-KLxRtf9Nwl7_font-weight: 600;
  --mixin-KLxRtf9Nwl7_letter-spacing: -0.5px;
  --mixin-KLxRtf9Nwl7_line-height: 1.3;
  --mixin-KLxRtf9Nwl7_white-space: pre-wrap;
  --mixin-9816vgCuFoU_font-family: "Inter", sans-serif;
  --mixin-9816vgCuFoU_color: #000000;
  --mixin-9816vgCuFoU_font-size: 20px;
  --mixin-9816vgCuFoU_font-weight: 600;
  --mixin-9816vgCuFoU_letter-spacing: -0.3px;
  --mixin-9816vgCuFoU_line-height: 1.5;
  --mixin-9816vgCuFoU_white-space: pre-wrap;
  --mixin-Kyej3OXR127_font-family: "Inter", sans-serif;
  --mixin-Kyej3OXR127_color: #000000;
  --mixin-Kyej3OXR127_font-size: 16px;
  --mixin-Kyej3OXR127_font-weight: 600;
  --mixin-Kyej3OXR127_line-height: 1.5;
  --mixin-Kyej3OXR127_white-space: pre-wrap;
  --mixin-Ok2Qrp-d2a5_border-left-color: #dddddd;
  --mixin-Ok2Qrp-d2a5_border-left-style: solid;
  --mixin-Ok2Qrp-d2a5_border-left-width: 3px;
  --mixin-Ok2Qrp-d2a5_color: #888888;
  --mixin-Ok2Qrp-d2a5_padding-left: 10px;
  --mixin-Ok2Qrp-d2a5_white-space: pre-wrap;
  --mixin-xDZ2PZfnQwC_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-xDZ2PZfnQwC_border-bottom-color: #dddddd;
  --mixin-xDZ2PZfnQwC_border-bottom-style: solid;
  --mixin-xDZ2PZfnQwC_border-bottom-width: 1px;
  --mixin-xDZ2PZfnQwC_border-left-color: #dddddd;
  --mixin-xDZ2PZfnQwC_border-left-style: solid;
  --mixin-xDZ2PZfnQwC_border-left-width: 1px;
  --mixin-xDZ2PZfnQwC_border-right-color: #dddddd;
  --mixin-xDZ2PZfnQwC_border-right-style: solid;
  --mixin-xDZ2PZfnQwC_border-right-width: 1px;
  --mixin-xDZ2PZfnQwC_border-top-color: #dddddd;
  --mixin-xDZ2PZfnQwC_border-top-style: solid;
  --mixin-xDZ2PZfnQwC_border-top-width: 1px;
  --mixin-xDZ2PZfnQwC_border-bottom-left-radius: 3px;
  --mixin-xDZ2PZfnQwC_border-bottom-right-radius: 3px;
  --mixin-xDZ2PZfnQwC_border-top-left-radius: 3px;
  --mixin-xDZ2PZfnQwC_border-top-right-radius: 3px;
  --mixin-xDZ2PZfnQwC_font-family: "Inconsolata";
  --mixin-xDZ2PZfnQwC_padding-bottom: 1px;
  --mixin-xDZ2PZfnQwC_padding-left: 4px;
  --mixin-xDZ2PZfnQwC_padding-right: 4px;
  --mixin-xDZ2PZfnQwC_padding-top: 1px;
  --mixin-xDZ2PZfnQwC_white-space: pre-wrap;
  --mixin-Iv5iX8R-jjs_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-Iv5iX8R-jjs_border-bottom-color: #dddddd;
  --mixin-Iv5iX8R-jjs_border-bottom-style: solid;
  --mixin-Iv5iX8R-jjs_border-bottom-width: 1px;
  --mixin-Iv5iX8R-jjs_border-left-color: #dddddd;
  --mixin-Iv5iX8R-jjs_border-left-style: solid;
  --mixin-Iv5iX8R-jjs_border-left-width: 1px;
  --mixin-Iv5iX8R-jjs_border-right-color: #dddddd;
  --mixin-Iv5iX8R-jjs_border-right-style: solid;
  --mixin-Iv5iX8R-jjs_border-right-width: 1px;
  --mixin-Iv5iX8R-jjs_border-top-color: #dddddd;
  --mixin-Iv5iX8R-jjs_border-top-style: solid;
  --mixin-Iv5iX8R-jjs_border-top-width: 1px;
  --mixin-Iv5iX8R-jjs_border-bottom-left-radius: 3px;
  --mixin-Iv5iX8R-jjs_border-bottom-right-radius: 3px;
  --mixin-Iv5iX8R-jjs_border-top-left-radius: 3px;
  --mixin-Iv5iX8R-jjs_border-top-right-radius: 3px;
  --mixin-Iv5iX8R-jjs_font-family: "Inconsolata";
  --mixin-Iv5iX8R-jjs_padding-bottom: 3px;
  --mixin-Iv5iX8R-jjs_padding-left: 6px;
  --mixin-Iv5iX8R-jjs_padding-right: 6px;
  --mixin-Iv5iX8R-jjs_padding-top: 3px;
  --mixin-Iv5iX8R-jjs_white-space: pre-wrap;
  --mixin-00SVJ4NoUPR_display: flex;
  --mixin-00SVJ4NoUPR_flex-direction: column;
  --mixin-00SVJ4NoUPR_align-items: stretch;
  --mixin-00SVJ4NoUPR_justify-content: flex-start;
  --mixin-00SVJ4NoUPR_list-style-position: outside;
  --mixin-00SVJ4NoUPR_padding-left: 40px;
  --mixin-00SVJ4NoUPR_position: relative;
  --mixin-00SVJ4NoUPR_list-style-type: decimal;
  --mixin-00SVJ4NoUPR_white-space: pre-wrap;
  --mixin-5GBhg9444dM_display: flex;
  --mixin-5GBhg9444dM_flex-direction: column;
  --mixin-5GBhg9444dM_align-items: stretch;
  --mixin-5GBhg9444dM_justify-content: flex-start;
  --mixin-5GBhg9444dM_list-style-position: outside;
  --mixin-5GBhg9444dM_padding-left: 40px;
  --mixin-5GBhg9444dM_position: relative;
  --mixin-5GBhg9444dM_list-style-type: disc;
  --mixin-5GBhg9444dM_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
.root_reset {
  font-family: var(--mixin-RZ510ztpWy8_font-family);
  font-size: var(--mixin-RZ510ztpWy8_font-size);
  font-weight: var(--mixin-RZ510ztpWy8_font-weight);
  font-style: var(--mixin-RZ510ztpWy8_font-style);
  color: var(--mixin-RZ510ztpWy8_color);
  text-align: var(--mixin-RZ510ztpWy8_text-align);
  text-transform: var(--mixin-RZ510ztpWy8_text-transform);
  line-height: var(--mixin-RZ510ztpWy8_line-height);
  letter-spacing: var(--mixin-RZ510ztpWy8_letter-spacing);
  white-space: var(--mixin-RZ510ztpWy8_white-space);
}

:where(.root_reset .plasmic_default__h1),
:where(.root_reset .h1),
:where(.root_reset :global(.__wab_expr_html_text) h1),
:where(.root_reset.plasmic_default__h1) {
  font-family: var(--mixin-F7hHOkU-fOV_font-family);
  color: var(--mixin-F7hHOkU-fOV_color);
  font-size: var(--mixin-F7hHOkU-fOV_font-size);
  font-weight: var(--mixin-F7hHOkU-fOV_font-weight);
  letter-spacing: var(--mixin-F7hHOkU-fOV_letter-spacing);
  line-height: var(--mixin-F7hHOkU-fOV_line-height);
}

:where(.root_reset .plasmic_default__h2),
:where(.root_reset .h2),
:where(.root_reset :global(.__wab_expr_html_text) h2),
:where(.root_reset.plasmic_default__h2) {
  font-family: var(--mixin-gvKKhtLvBWe_font-family);
  color: var(--mixin-gvKKhtLvBWe_color);
  font-size: var(--mixin-gvKKhtLvBWe_font-size);
  font-weight: var(--mixin-gvKKhtLvBWe_font-weight);
  letter-spacing: var(--mixin-gvKKhtLvBWe_letter-spacing);
  line-height: var(--mixin-gvKKhtLvBWe_line-height);
}

:where(.root_reset .plasmic_default__h3),
:where(.root_reset .h3),
:where(.root_reset :global(.__wab_expr_html_text) h3),
:where(.root_reset.plasmic_default__h3) {
  font-family: var(--mixin-m2K2I4sQk2J_font-family);
  color: var(--mixin-m2K2I4sQk2J_color);
  font-size: var(--mixin-m2K2I4sQk2J_font-size);
  font-weight: var(--mixin-m2K2I4sQk2J_font-weight);
  letter-spacing: var(--mixin-m2K2I4sQk2J_letter-spacing);
  line-height: var(--mixin-m2K2I4sQk2J_line-height);
}

:where(.root_reset .plasmic_default__h4),
:where(.root_reset .h4),
:where(.root_reset :global(.__wab_expr_html_text) h4),
:where(.root_reset.plasmic_default__h4) {
  font-family: var(--mixin-KLxRtf9Nwl7_font-family);
  color: var(--mixin-KLxRtf9Nwl7_color);
  font-size: var(--mixin-KLxRtf9Nwl7_font-size);
  font-weight: var(--mixin-KLxRtf9Nwl7_font-weight);
  letter-spacing: var(--mixin-KLxRtf9Nwl7_letter-spacing);
  line-height: var(--mixin-KLxRtf9Nwl7_line-height);
}

:where(.root_reset .plasmic_default__h5),
:where(.root_reset .h5),
:where(.root_reset :global(.__wab_expr_html_text) h5),
:where(.root_reset.plasmic_default__h5) {
  font-family: var(--mixin-9816vgCuFoU_font-family);
  color: var(--mixin-9816vgCuFoU_color);
  font-size: var(--mixin-9816vgCuFoU_font-size);
  font-weight: var(--mixin-9816vgCuFoU_font-weight);
  letter-spacing: var(--mixin-9816vgCuFoU_letter-spacing);
  line-height: var(--mixin-9816vgCuFoU_line-height);
}

:where(.root_reset .plasmic_default__h6),
:where(.root_reset .h6),
:where(.root_reset :global(.__wab_expr_html_text) h6),
:where(.root_reset.plasmic_default__h6) {
  font-family: var(--mixin-Kyej3OXR127_font-family);
  color: var(--mixin-Kyej3OXR127_color);
  font-size: var(--mixin-Kyej3OXR127_font-size);
  font-weight: var(--mixin-Kyej3OXR127_font-weight);
  line-height: var(--mixin-Kyej3OXR127_line-height);
}

:where(.root_reset .plasmic_default__blockquote),
:where(.root_reset .blockquote),
:where(.root_reset :global(.__wab_expr_html_text) blockquote),
:where(.root_reset.plasmic_default__blockquote) {
  color: var(--mixin-Ok2Qrp-d2a5_color);
  padding-left: var(--mixin-Ok2Qrp-d2a5_padding-left);
  border-left: var(--mixin-Ok2Qrp-d2a5_border-left-width)
    var(--mixin-Ok2Qrp-d2a5_border-left-style)
    var(--mixin-Ok2Qrp-d2a5_border-left-color);
}

:where(.root_reset .plasmic_default__code),
:where(.root_reset .code),
:where(.root_reset :global(.__wab_expr_html_text) code),
:where(.root_reset.plasmic_default__code) {
  background: #f8f8f8;
  font-family: var(--mixin-xDZ2PZfnQwC_font-family);
  border-radius: var(--mixin-xDZ2PZfnQwC_border-top-left-radius)
    var(--mixin-xDZ2PZfnQwC_border-top-right-radius)
    var(--mixin-xDZ2PZfnQwC_border-bottom-right-radius)
    var(--mixin-xDZ2PZfnQwC_border-bottom-left-radius);
  padding: var(--mixin-xDZ2PZfnQwC_padding-top)
    var(--mixin-xDZ2PZfnQwC_padding-right)
    var(--mixin-xDZ2PZfnQwC_padding-bottom)
    var(--mixin-xDZ2PZfnQwC_padding-left);
  border-top: var(--mixin-xDZ2PZfnQwC_border-top-width)
    var(--mixin-xDZ2PZfnQwC_border-top-style)
    var(--mixin-xDZ2PZfnQwC_border-top-color);
  border-right: var(--mixin-xDZ2PZfnQwC_border-right-width)
    var(--mixin-xDZ2PZfnQwC_border-right-style)
    var(--mixin-xDZ2PZfnQwC_border-right-color);
  border-bottom: var(--mixin-xDZ2PZfnQwC_border-bottom-width)
    var(--mixin-xDZ2PZfnQwC_border-bottom-style)
    var(--mixin-xDZ2PZfnQwC_border-bottom-color);
  border-left: var(--mixin-xDZ2PZfnQwC_border-left-width)
    var(--mixin-xDZ2PZfnQwC_border-left-style)
    var(--mixin-xDZ2PZfnQwC_border-left-color);
}

:where(.root_reset .plasmic_default__pre),
:where(.root_reset .pre),
:where(.root_reset :global(.__wab_expr_html_text) pre),
:where(.root_reset.plasmic_default__pre) {
  background: #f8f8f8;
  font-family: var(--mixin-Iv5iX8R-jjs_font-family);
  border-radius: var(--mixin-Iv5iX8R-jjs_border-top-left-radius)
    var(--mixin-Iv5iX8R-jjs_border-top-right-radius)
    var(--mixin-Iv5iX8R-jjs_border-bottom-right-radius)
    var(--mixin-Iv5iX8R-jjs_border-bottom-left-radius);
  padding: var(--mixin-Iv5iX8R-jjs_padding-top)
    var(--mixin-Iv5iX8R-jjs_padding-right)
    var(--mixin-Iv5iX8R-jjs_padding-bottom)
    var(--mixin-Iv5iX8R-jjs_padding-left);
  border-top: var(--mixin-Iv5iX8R-jjs_border-top-width)
    var(--mixin-Iv5iX8R-jjs_border-top-style)
    var(--mixin-Iv5iX8R-jjs_border-top-color);
  border-right: var(--mixin-Iv5iX8R-jjs_border-right-width)
    var(--mixin-Iv5iX8R-jjs_border-right-style)
    var(--mixin-Iv5iX8R-jjs_border-right-color);
  border-bottom: var(--mixin-Iv5iX8R-jjs_border-bottom-width)
    var(--mixin-Iv5iX8R-jjs_border-bottom-style)
    var(--mixin-Iv5iX8R-jjs_border-bottom-color);
  border-left: var(--mixin-Iv5iX8R-jjs_border-left-width)
    var(--mixin-Iv5iX8R-jjs_border-left-style)
    var(--mixin-Iv5iX8R-jjs_border-left-color);
}

:where(.root_reset .plasmic_default__ol),
:where(.root_reset .ol),
:where(.root_reset :global(.__wab_expr_html_text) ol),
:where(.root_reset.plasmic_default__ol) {
  display: var(--mixin-00SVJ4NoUPR_display);
  flex-direction: var(--mixin-00SVJ4NoUPR_flex-direction);
  align-items: var(--mixin-00SVJ4NoUPR_align-items);
  justify-content: var(--mixin-00SVJ4NoUPR_justify-content);
  list-style-position: var(--mixin-00SVJ4NoUPR_list-style-position);
  padding-left: var(--mixin-00SVJ4NoUPR_padding-left);
  position: var(--mixin-00SVJ4NoUPR_position);
  list-style-type: var(--mixin-00SVJ4NoUPR_list-style-type);
  flex-column-gap: var(--mixin-00SVJ4NoUPR_flex-column-gap);
}

:where(.root_reset .plasmic_default__ul),
:where(.root_reset .ul),
:where(.root_reset :global(.__wab_expr_html_text) ul),
:where(.root_reset.plasmic_default__ul) {
  display: var(--mixin-5GBhg9444dM_display);
  flex-direction: var(--mixin-5GBhg9444dM_flex-direction);
  align-items: var(--mixin-5GBhg9444dM_align-items);
  justify-content: var(--mixin-5GBhg9444dM_justify-content);
  list-style-position: var(--mixin-5GBhg9444dM_list-style-position);
  padding-left: var(--mixin-5GBhg9444dM_padding-left);
  position: var(--mixin-5GBhg9444dM_position);
  list-style-type: var(--mixin-5GBhg9444dM_list-style-type);
  flex-column-gap: var(--mixin-5GBhg9444dM_flex-column-gap);
}
